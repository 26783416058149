import type { CmsPageEntity } from '@shopware-pwa/types';
import { isLandingPage, isProduct, isCategory } from '@shopware-pwa/helpers-next';
import { ImgProxy } from '~/helpers/ImgProxy';
import type { Schemas } from '#shopware';
import type { Ref } from 'vue';
import type { AhProduct } from '~/types/models/product/product';
import { useListingState } from '~/composables/useListingState';

const NOINDEX_NOFOLLOW = 'noindex,nofollow';
const NOINDEX_FOLLOW = 'noindex,follow';
const INDEX_FOLLOW = 'index,follow';

export function useCmsHead(entity?: Ref<CmsPageEntity> | ComputedRef<CmsPageEntity>): void {
    const route = useRoute();
    const { isHomePath } = useSeoPath();
    const { shopTitle } = useAppConfig();
    const unrefEntity = unref(entity) as CmsPageEntity;
    const { activeFiltersCount, activeSorting } = useListingState();
    const isSearchPage = computed(() => !!route.name?.toString()?.startsWith('search__'));

    // get title and meta tags available in the Shopware instance
    const { title: metaTitle, meta } = useCmsMeta(unrefEntity);

    const TITLE_MAX_LENGTH = 70;
    const DESCRIPTION_MAX_LENGTH = 150;

    const getTitle = (): string => {
        const title = metaTitle.value;

        if (isHomePath.value || isSearchPage.value) return shopTitle;

        if (isProduct(unrefEntity)) {
            if (`${title} | ${shopTitle}`.length <= TITLE_MAX_LENGTH) {
                return `${title} | ${shopTitle}`;
            }
        }

        return title;
    };

    const cleanText = (text: string) => {
        return text
            .replace(/<li[^>]*>/g, ' | ') // Replace opening <li> with |, ignoring attributes
            .replace(/<\/?(ul|ol|li)[^>]*>/g, ' ') // Remove all other list-tags including </li>, <ul>, </ul>, <ol>, and </ol>
            .replace(/<[^>]*>/g, ' ') // Remove all HTML tags
            .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
            .replace(/^\s\|\s/, '')
            .trim();
    };

    const truncateText = (text: string, maxLength: number) => {
        if (text.length > maxLength) {
            const truncatedText = text
                .slice(0, maxLength - 3)
                .replace(/\s\|\s?$/, '')
                .replace(/,$/, '')
                .trim();

            return truncatedText.endsWith('.') ? truncatedText : truncatedText + '...';
        }

        return text;
    };

    const getDescription = (): string | undefined => {
        if (isProduct(unrefEntity)) {
            const subTitle =
                (unrefEntity as AhProduct).extensions?.attributes?.translated?.subtitle ??
                (unrefEntity as AhProduct).extensions?.attributes?.subtitle ??
                null;
            const originalProductDesc = unrefEntity.translated?.description ?? unrefEntity.description ?? '';
            const productDesc = subTitle ? subTitle + ' | ' + cleanText(originalProductDesc) : originalProductDesc;

            if (productDesc.length) {
                return truncateText(cleanText(productDesc), DESCRIPTION_MAX_LENGTH);
            }
        }

        const metaDescription = meta.value.find((meta) => meta.name === 'description')?.content ?? '';

        return truncateText(cleanText(metaDescription), DESCRIPTION_MAX_LENGTH) || undefined;
    };

    const productImage = computed(() => (unrefEntity as Schemas['Product'])?.cover?.media?.url ?? '');
    const categoryImage = computed(() => (unrefEntity as Schemas['Category'])?.extensions?.imageUrl?.value ?? '');

    const getOgImage = () => {
        let ogImage = undefined;

        if (isProduct(unrefEntity)) {
            ogImage = ImgProxy.getResizeServicePath('1200x1200', productImage.value);
        } else if (isCategory(unrefEntity)) {
            ogImage = ImgProxy.getResizeServicePath('1200x1200', categoryImage.value);
        }

        return ogImage;
    };

    const isNoIndexListing = () => {
        const routeQuery = route.query;
        const isPaginated = !!(routeQuery.page && Number(routeQuery.page) > 1);

        return !!(isPaginated || activeFiltersCount.value > 1 || activeSorting.value);
    };

    const getRobotsIndex = (): string | undefined => {
        if (isSearchPage.value) {
            if (isNoIndexListing()) {
                return NOINDEX_FOLLOW;
            }

            return INDEX_FOLLOW;
        }

        if (!route.name?.toString()?.startsWith('slug__')) {
            return NOINDEX_NOFOLLOW;
        }

        if (isCategory(unrefEntity)) {
            if (
                unrefEntity?.extensions?.attributes?.loggedInRestriction ||
                unrefEntity?.extensions?.categoryNoIndex?.noIndex
            ) {
                return NOINDEX_NOFOLLOW;
            }

            if (isNoIndexListing()) {
                return NOINDEX_FOLLOW;
            }

            return INDEX_FOLLOW;
        } else if (isProduct(unrefEntity)) {
            if (unrefEntity?.extensions?.attributes?.isBware || unrefEntity?.extensions?.productNoIndex?.noIndex) {
                return NOINDEX_NOFOLLOW;
            }

            return INDEX_FOLLOW;
        } else if (isLandingPage(unrefEntity)) {
            if (unrefEntity?.extensions?.landingPageNoIndex?.noIndex) {
                return NOINDEX_NOFOLLOW;
            }

            return INDEX_FOLLOW;
        }

        return undefined;
    };

    const enhancedMeta = computed(() => [
        {
            name: 'description',
            content: getDescription(),
        },
        {
            name: 'robots',
            content: getRobotsIndex(),
        },
        {
            name: 'og:title',
            content: getTitle(),
        },
        {
            name: 'og:description',
            content: getDescription(),
        },
        {
            name: 'og:image',
            content: getOgImage(),
        },
        {
            name: 'og:type',
            content: 'website',
        },
        {
            name: 'og:site_name',
            content: shopTitle,
        },
    ]);

    // set head internally
    useHead({
        title: getTitle(),
        meta: enhancedMeta,
    });
}
